import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import dompurify from "dompurify";
import "./movieDetails.scss";
import moment from "moment";
import { RootState } from "../../../../store";
import { getMovieDetailApi } from "../../../../services/films";
import {
  findCinemaObject,
  formatAdvanceMovieDetail,
  formatMovieDetails,
} from "../../../../utils/formatter";
import HtmlRender from "../../../../components/UI/HtmlRender";
import DateSlider from "../../../../components/UI/DateSlider";
import SessionTimes from "../../../../components/SessionTimes/AU";
import { sortBy } from "lodash";
import { MOVIE_TYPES } from "../../../../constants/app";
import { IMOVIE_TYPES } from "../../../../models/common";
import { ICinemas, IFormatMovie } from "../../../../models/cinema";
import {
  setCinemaAction,
  getSlidesWatcher,
  setFooterType,
} from "../../../../store/application/action";
import Slider from "../../../../components/UI/Slider";
import { API_REQUEST_TYPE, URLS } from "../../../../constants/url";
import { Tooltip } from "react-tooltip";
import HelmetTitle from "../../../../components/Helmet";
import { pushDataLayer } from "../../../../utils/dataLayer";

interface IMovieDetailsProps {
  movieDataObj?: any;
}

const MovieDetails: React.FC<IMovieDetailsProps> = () => {
  const { countryId, slides, cinemas, currentCinema, backUrl, isLoggedIn } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      slides: state.applicationReducer.slides,
      cinemas: state.applicationReducer.cinemas,
      currentCinema: state.applicationReducer.currentCinema,
      backUrl: state.applicationReducer.movieDetailBackUrl,
      isLoggedIn: state.authReducer.isLoggedIn
    })
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cinemaId, movieGroupId } = useParams();

  const [movieDetails, setMovieDetails] = useState<any>();
  const [movieTypes, setMovieTypes] = useState<IMOVIE_TYPES>(MOVIE_TYPES);
  const [isBookable, setIsBookable] = useState<number>(1);
  const [dateArray, setDateArray] = useState<Array<any>>([]);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [showArray, setShowArray] = useState<any>([]);

  const safeURL = useMemo(() => {
    const spilturl = new RegExp(/(?:\?v=)([^&]+)*/);
    if (movieDetails && movieDetails.trailerUrl) {
      const videoId: any = spilturl.exec(movieDetails.trailerUrl);
      let id = "";
      if (videoId) {
        id = videoId[1];
      } else {
        id = movieDetails.trailerUrl.split("/").pop();
      }
      return dompurify.sanitize("https://www.youtube.com/embed/" + id);
    }
    return "";
  }, [movieDetails]);

  useEffect(() => {
    if (countryId) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.MOVIES
        })
      );
    }
  }, [countryId]);

  useEffect(() => {
    if (countryId && cinemaId && movieGroupId && cinemas) {
      const getMovieDetails = async () => {
        let selectedCinema: ICinemas | null =
          findCinemaObject(cinemas, cinemaId) || null;
        if (selectedCinema) {
          if (!currentCinema || currentCinema.slug !== selectedCinema.slug) {
            setCinemaAction(currentCinema);
          }
          setIsBookable(
            selectedCinema && selectedCinema.hasOwnProperty("etix")
              ? Number(selectedCinema["etix"])
              : 0
          );
          const params = {
            countryId,
            cinemaId,
            movieGroupId,
            sort: true,
          };

          const { status, data } = await getMovieDetailApi(params);
          if (status) {
            const [formatData] =
              data && data[0].hasOwnProperty("sessionAttributes")
                ? formatMovieDetails(data)
                : formatAdvanceMovieDetail(data);
            if (formatData) {
              setMovieDetails(formatData);
              setupFlag(formatData);
              pushDataLayer({
                isLoggedIn,
                films: formatData,
              }, 'view_item')
            }
          }
        }
      };
      getMovieDetails();
      dispatch(setFooterType(5));
    } else if (countryId && movieGroupId && cinemas && currentCinema) {
      navigate(`/movies/details/${currentCinema?.slug}/${movieGroupId}`);
    }

    const setupFlag = (movies: IFormatMovie) => {
      const tempMoviesTypes = movieTypes;
      Object.values(movies.showtimes).forEach((sessions: any) => {
        Object.values(sessions).forEach((session: any) => {
          const sessionAttributes = session?.session_attributes;
          if (
            sessionAttributes &&
            Object.keys(tempMoviesTypes).includes(
              sessionAttributes.toLowerCase()
            )
          ) {
            tempMoviesTypes[sessionAttributes.toLowerCase()].active = true;
          }
        });
      });
      setMovieTypes(tempMoviesTypes);
    };
  }, [countryId, cinemaId, movieGroupId, cinemas, currentCinema]);

  useEffect(() => {
    if (movieDetails) {
      const dateArr: any = Object.keys(movieDetails.showtimes);
      setDateArray(dateArr);
      if (dateArr && dateArr.length > 0) {
        const currentDate = dateArr[0];
        const dates = dateArr.map((v: string) => {
          return {
            disp_date:
              v == moment().format("YYYY-MM-DD")
                ? "TODAY"
                : moment(v).format("DD/MM ddd"),
            value: v,
          };
        });
        setShowArray(
          sortBy(
            movieDetails?.showtimes[currentDate],
            (row) => row["showDateTime"]
          )
        );
        setSelectedDate(currentDate);
        setDateArray(dates);
      }
    }
  }, [movieDetails]);

  const onChangeDate = (dateObject: { disp_date: string; value: string }) => {
    setSelectedDate(dateObject.value);
    setShowArray(
      sortBy(
        movieDetails?.showtimes[dateObject.value],
        (row) => row["showDateTime"]
      )
    );
  };

  const errorHandler = (event: any) => {
    event.target.src = URLS.ERROR_IMAGE;
  };

  return (
    <section className="movie-details">
      <Slider data={slides} />
      {movieDetails ? (
        <div className="container mt-3">
          <HelmetTitle title={movieDetails.movieName} description={movieDetails.movieName}/>
          <div className="row">
            <div className="col-4 col-md-4 mt-md-3">
              <Link className="back_to_all_movies" to={backUrl}>
                All Movies
              </Link>
              <div className="media">
                <img
                  className="align-self-start"
                  src={movieDetails.images[0].large}
                  alt={movieDetails.movieName}
                  title={movieDetails.movieName}
                  onError={errorHandler}
                />
              </div>
            </div>
            <div className="col-8 col-md-6 mobile_show">
              <h5 className="mt-0 title">{movieDetails.movieName}</h5>
              <HtmlRender
                {...{ className: "description" }}
                content={movieDetails.outline}
              />
            </div>
            <div className="col-12 col-xl-8 col-md-12 mt-4 mt-md-3">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="legend_text">
                    <ul>
                      {Object.values(movieTypes).map((v) => {
                        return v.active ? (
                          <li className={`${v.class} text-nowrap`}>
                            {v.title}
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </div>
                  <DateSlider
                    data={dateArray}
                    onChange={onChangeDate}
                    selected={selectedDate}
                  />
                  <SessionTimes data={showArray} isBookable={isBookable} />
                </div>
              </div>
              <div className="row">
                <div className={`col-8 col-md-6 mt-4 mobile_hide`}>
                  <h5 className="mt-0 title">{movieDetails.movieName}</h5>
                  <HtmlRender
                    {...{ className: "description" }}
                    content={movieDetails.outline}
                  />
                </div>
                {movieDetails.trailerUrl ? (
                  <div className="col-12 col-md-6 mt-4">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe
                        className="embed-responsive-item"
                        src={safeURL}
                        title={movieDetails.movieName}
                      ></iframe>
                    </div>
                  </div>
                ) : null}
                <div className="col-12 col-md-6">
                  <table className="movie_details">
                    <tbody>
                      {movieDetails.releaseDate ? (
                        <tr>
                          <th>Release:</th>
                          <td>
                            {moment(movieDetails.releaseDate).format(
                              "DD MMMM Y"
                            )}
                          </td>
                        </tr>
                      ) : null}
                      {movieDetails.movieDuration ? (
                        <tr>
                          <th>Length:</th>
                          <td>{movieDetails.movieDuration} mins</td>
                        </tr>
                      ) : null}
                      {movieDetails.genre ? (
                        <tr>
                          <th id="MovieGenre">Genre:</th>
                          <td>{movieDetails.genre}</td>
                        </tr>
                      ) : null}
                      <tr className="certification_wrap">
                        <th id="MovieRating">Rating:</th>
                        <td className="rating">
                          {/* <HtmlRender
                            content={movieDetails.ratingExplanation}
                          /> */}
                          <span className="certification_icon">
                            <img
                              src={movieDetails.ratingIcon}
                              alt={movieDetails.ratingTitle}
                              onError={errorHandler}
                              data-tooltip-id="rating-explanation" data-tooltip-html={movieDetails.ratingExplanation}
                            />
                             <Tooltip id="rating-explanation" className="tool-tip" />
                          </span>
                          <HtmlRender
                            {...{ className: "ratingTitle ml-2" }}
                            content={movieDetails.ratingName}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-12 col-md-6">
                  <table className="movie_details rhs">
                    <tbody>
                      {movieDetails.distributorDetails ? (
                        <tr>
                          <th>Distributor:</th>
                          <td>{movieDetails.distributorDetails}</td>
                        </tr>
                      ) : null}
                      {movieDetails.director ? (
                        <tr>
                          <th>Directors:</th>
                          <td>{movieDetails.director}</td>
                        </tr>
                      ) : null}
                      {movieDetails.cast ? (
                        <tr>
                          <th>Cast:</th>
                          <td>
                            <HtmlRender content={movieDetails.cast} />
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
};
export default MovieDetails;
