import React, { FC, useEffect, useMemo, useState } from "react";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import Slick from "react-slick";
import { IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import { ANG_MEMBERSHIP_EXPIRY_DAYS, COUNTRY, MEMBERSHIP_TYPE } from "../../../../constants/app";
import Loyalty from "../../AuthMenu/Loyalty";
import { purchaseHistoryApi, walletTokenApi } from "../../../../services/auth";
import { calculateCummulativePoints, formatLoyaltyPayload, formatMembershipExpireDate, formatMembershipExpireDays, formatMembershipType, formatRewardPoints } from "../../../../utils/rewards";
import { MEMBERSHIP_CONTENT } from "../../../../constants/membership";
import Modal from "../../../../components/UI/Modal";
import Barcode from "react-barcode";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../../constants/path";
import { openSliderAction } from "../../../../store/application/action";
import { upgrademembership } from "../../../../services/rewards";
import { ITransactionHistory } from "../../../../models/auth";
import './account.scss';
import moment from "moment";

interface IAccountProps { }


const settings = {
    className: "",
    centerPadding: "10px",
    dots: true,
    lazyload: true,
    infinite: false,
    speed: 300,
    autoplay: false,
    autoplaySpeed: 4000,
    cssEase: "linear",
    fade: false,
    slidesPerRow: 5,
    swipeToSlide: true,
    arrows: true,
    appendDots: (dots: any) => <div>  <div>Slide to see more</div>
        <div>
            <ul>{dots}</ul>
        </div></div>,
    customPaging: (i: any) => (
        <div className="ft-slick__dots--custom">
            <div className="loading" />
        </div>
    ),
};

const Account: FC<IAccountProps> = () => {
    const { countryId, userDetails, memberDetail, isLoggedIn, currentCinema } = useSelector(
        (state: RootState) => ({
            countryId: state.applicationReducer.countryId,
            userDetails: state.authReducer.userDetails,
            memberDetail: state.authReducer.memberDetail,
            isLoggedIn: state.authReducer.isLoggedIn,
            currentCinema: state.applicationReducer.currentCinema
        })
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [showHistory, setShowhistory] = useState(false);
    const [purchaseHistory, setPurchaseHistory] = useState<{ date: string, tickets: ITransactionHistory[] }[]>([]);

    const showAppleWallet = useMemo(() => {
        if (
            /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        ) {
            return true;
        } else {
            return false;
        }
    }, [])

    const googleWalletClick = async () => {
        if (userDetails && userDetails?.cardNumber && userDetails.token) {
            window.open(URLS.GOOGLE_WALLET + userDetails.token, "_blank");
        } else {
            const {
                status,
                data: { data, statusCode },
            } = await walletTokenApi(formatLoyaltyPayload(userDetails!, countryId, memberDetail!, 'google'));
            if (status === 200 && statusCode === 200 && data?.token) {
                window.open(URLS.GOOGLE_WALLET + data?.token, "_blank");
            }
        }
    };

    const appleWalletClick = async () => {
        const {
            status,
            data: { data, statusCode },
        } = await walletTokenApi(formatLoyaltyPayload(userDetails!, countryId, memberDetail!, 'apple'));
        if (status === 200 && statusCode === 200 && data?.token) {
            window.open(URLS.APPLE_WALLET + data?.token, "_blank");
        }
        // }
    };

    const logo = useMemo(() => {
        if (countryId) {
            if (countryId === COUNTRY.STA) {
                return {
                    free: MEMBERSHIP_CONTENT[COUNTRY.STA]['BASIC']['membershipLogo'],
                    paid: MEMBERSHIP_CONTENT[COUNTRY.STA]['BOOST']['membershipLogo']
                }
            } else if (countryId === COUNTRY.ANG) {
                return {
                    free: MEMBERSHIP_CONTENT[COUNTRY.ANG]['CLUB']['membershipLogo'],
                    paid: MEMBERSHIP_CONTENT[COUNTRY.ANG]['GOLD']['membershipLogo']
                }
            } else {
                return {
                    free: MEMBERSHIP_CONTENT[COUNTRY.AUS]['BASIC']['membershipLogo'],
                    paid: MEMBERSHIP_CONTENT[COUNTRY.AUS]['BOOST']['membershipLogo']
                }
            }
        } else {
            return {
                free: '',
                paid: ''
            };
        }
    }, [countryId])

    const membershipType = useMemo(() => {
        return formatMembershipType(memberDetail!)
    }, [memberDetail])

    const openModal = () => {
        setModal(true);
    }

    const onCloseModal = () => {
        setModal(false);
    }
    const txnPoints = useMemo(() => {
        return formatRewardPoints(memberDetail!);
    }, [memberDetail]);

    const onChangeHistory = () => {
        setShowhistory(!showHistory);
    }

    const showReward = () => {
        navigate(`/${ROUTES.REWARDS}/${ROUTES.REWARDITEM}`);
        dispatch(openSliderAction({ open: false, type: "" }));
    }

    const upgrade = async () => {
        await upgrademembership(countryId, currentCinema, userDetails, dispatch);
        dispatch(openSliderAction({ open: false, type: "" }));
    }

    const goToRewards = () => {
        navigate(`/${ROUTES.REWARDS}/${ROUTES.SUMMARY}`);
        dispatch(openSliderAction({ open: false, type: "" }));
    }

    const getPurchaseHistory = async () => {

        const payload = {
            countryId: countryId,
            MemberRefId: memberDetail?.LoyaltyMember?.MemberId,
            cardNumber: memberDetail?.LoyaltyMember?.CardNumber,
            UserSessionId: memberDetail?.LoyaltyMember?.CardNumber,
        };
        const {
            status,
            data: {
                data,
                statusCode
            }
        } = await purchaseHistoryApi(payload) as any;
        if (status === 200 && statusCode === 200 && data) {
            if (
                data.result &&
                data.result.MemberTransactions &&
                data.result.MemberTransactions.length > 0
            ) {
                let history = calculateCummulativePoints(data, memberDetail!);
                history = history.reverse();
                const tickets = history.reduce((acc: any, v: ITransactionHistory) => {
                    const timestamp = moment(v.TransactionDate).format('YYYY')
                    if (!acc[timestamp]) {
                        acc[timestamp] = []
                    }
                    if (v) {
                        acc[timestamp].push(v);
                    }
                    return acc;
                }, {});

                history = Object.keys(tickets).map((v) => {
                    return {
                        date: v,
                        tickets: tickets[v]
                    }
                })
                setPurchaseHistory(history.reverse());
            }
        }
    };

    useEffect(() => {
        if (memberDetail) {
            getPurchaseHistory();
        }
    }, [memberDetail]);

    const expiryDate = useMemo(()=>{
        return formatMembershipExpireDate(memberDetail!)
    },[memberDetail])

    const isGoingExpire = useMemo(()=>{
        return formatMembershipExpireDays(memberDetail!) <= ANG_MEMBERSHIP_EXPIRY_DAYS
    },[memberDetail]);

    return <>{
        // countryId === COUNTRY.AUS || countryId === COUNTRY.NZ ?
        // <div className="reading-loyalty">
        //     <Loyalty />
        //     <div className="add-wallet">
        //         <div className="add-ticket">Add membership card to:</div>
        //         <div className="wallet-button">
        //             {showAppleWallet ?
        //                 <div className="apple-wallet-btn">
        //                     <img onClick={appleWalletClick} src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'apple_wallet.svg'} />
        //                 </div> :
        //                 <div className="google-wallet-btn">
        //                     <img onClick={googleWalletClick} src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'google_wallet.svg'} />
        //                 </div>
        //             }
        //         </div>
        //     </div>
        // </div> :
        <div className="account-tab-wrapper">

            <div className={`rewards brand_${countryId} ${membershipType}`}>
                <div className="logo">
                    <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}` +
                        `${membershipType === MEMBERSHIP_TYPE.GOLD ? logo.paid : logo.free}`} />
                </div>
                <div className={`content-wrapper ${membershipType === MEMBERSHIP_TYPE.CLUB?'club':''}`}>
                    <div className="content1">
                        <div className="member-title">Member #</div>
                        <div className="memberId">{memberDetail?.LoyaltyMember?.CardNumber}</div>
                    </div>
                    {membershipType === MEMBERSHIP_TYPE.GOLD?<div className="content2">
                        <div className={`expiry-on ${isGoingExpire?'active':''}`}>{isGoingExpire?'Expiring Soon On':'Expires On'}</div>
                        <div className="date">{expiryDate}</div>
                    </div>:null}
                </div>
                <div className="scan-barcode">
                    <button onClick={openModal}>
                        <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'scan_barcode.svg'} />
                        View/scan barcode
                    </button>
                </div>
                <div className="points-wrapper">
                    <div className="points">
                        <div className="label">Points</div>
                        <div className="value">{txnPoints}</div>
                    </div>
                    <div className="history" onClick={onChangeHistory}>
                        <div className="label">{showHistory ? 'Hide' : 'See'} History</div>
                        <div className="arrow">
                            {showHistory ?
                                <img className="black-arrow" src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'arrow_up_black.svg'} /> :
                                <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'arrow-bottom-white.svg'} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {showHistory ? <div className={`points-history brand_${countryId} ${membershipType}`}>
                <h5>Transaction History</h5>
                <div className="history">
                    <Slick
                        {...settings}
                        className="carousel"

                    >
                        {purchaseHistory.length ? purchaseHistory.map((bh: { date: string, tickets: ITransactionHistory[] }) =>

                            bh.tickets.map((v: ITransactionHistory, i: number) => <>
                                {i == 0 ?
                                    <div className="year">
                                        {bh.date}
                                    </div> : null}
                                <div className="history-card">
                                    <div className="content">
                                        <div className="date">
                                            <span>{moment(v.TransactionDate).parseZone().format('MMM')}</span>
                                            <span>{moment(v.TransactionDate).parseZone().format('DD')}</span>
                                        </div>
                                        <div className="desc">
                                            <h5>{v.ItemName}</h5>
                                            {/* <p className="name">Respect</p> */}
                                            <p className="cinema">{v.CinemaName}</p>
                                        </div>
                                    </div>
                                    <div className="points">
                                        <div className="point"><span className="pos">{v.PointsEarned > 0?'+': v.PointsRedeemed > 0?'-':''}</span>{v.PointsEarned > 0?v.PointsEarned: v.PointsRedeemed > 0?v.PointsRedeemed:0}</div>
                                        <div className="label">points</div>
                                    </div>
                                </div>
                            </>))

                            : <div className="no-tickets">
                                <p>No Data Available</p>
                            </div>}
                    </Slick>
                </div>
            </div> : null}
            <div className="add-wallet">
                <div className="add-ticket">Add membership card to:</div>
                <div className="wallet-button">
                    {showAppleWallet ?
                        <div className="apple-wallet-btn">
                            <img onClick={appleWalletClick} src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'apple_wallet.svg'} />
                        </div> :
                        <div className="google-wallet-btn">
                            <img onClick={googleWalletClick} src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'google_wallet.svg'} />
                        </div>
                    }
                </div>
            </div>
            <div className={`reward-btns brand_${countryId} ${membershipType}`}>
                {isLoggedIn && membershipType === MEMBERSHIP_TYPE.CLUB ? <button className="upgrade" onClick={upgrade}>UPGRADE TO {countryId === COUNTRY.AUS || countryId === COUNTRY.NZ?'READING BOOST': countryId === COUNTRY.STA?'ANGELIKA BOOST':'ANGELIKA GOLD'}</button> : null}
                <button className="see-rewards" onClick={showReward}>See my available rewards</button>
                <button className="go-to-rewards" onClick={goToRewards}>Go To My Rewards Page</button>
                <button className="faq" onClick={goToRewards}><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'help.svg'} />SEE REWARD FAQS</button>
            </div>
        </div>}
        <Modal
            className={`barcode-viewer content-modal`}
            visible={modal}
            showCloseBtn={false}
        >
            <div className="modal fade show" style={{ display: "block" }}>
                <div
                    className={"modal-dialog modal-dialog-centered modal-md"}
                >
                    <div className={`modal-content`}>
                        <div className="modal-header-image">
                            <button
                                type="button"
                                className="close-modal"
                                onClick={onCloseModal}
                            >
                                <img
                                    className="close_icon"
                                    src={URLS.CLOSE_ICON}
                                    alt="close_icon"
                                    title="close_icon"
                                />
                            </button>
                        </div>
                        <div className="modal-header">
                            {countryId === COUNTRY.ANG && membershipType === MEMBERSHIP_TYPE.CLUB ? <h4>Scan Angelika Rewards Club membership barcode.</h4> :
                                countryId === COUNTRY.ANG && membershipType === MEMBERSHIP_TYPE.GOLD ? <h4>Scan Angelika Rewards Gold membership barcode.</h4> :
                                    countryId === COUNTRY.STA && membershipType === MEMBERSHIP_TYPE.CLUB ? <h4>Scan Angelika Rewards Basic membership barcode.</h4> :
                                        countryId === COUNTRY.ANG && membershipType === MEMBERSHIP_TYPE.GOLD ? <h4>Scan Angelika Rewards Boost membership barcode.</h4> : null}
                        </div>
                        <div className="modal-body">
                            <div className="contact-us-form form_page">
                                <div className="row">
                                    <div className="col-12 text-center">

                                        <Barcode
                                            width={3}
                                            displayValue={false}
                                            value={memberDetail?.LoyaltyMember?.CardNumber!}
                                        />
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="button_wrap text-center div">
                                            <button
                                                type="button"
                                                className="btn black_btn mx-1"
                                                onClick={() => onCloseModal()}
                                            >
                                                CLOSE
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal></>

};

export default Account;
