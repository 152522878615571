import { createBrowserHistory } from 'history';
import { configureStore, isImmutableDefault } from '@reduxjs/toolkit';
import createSagaMiddleware from  'redux-saga';
import RootReducer from './rootReducer';
import RootSaga from './rootSaga';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
reducer: RootReducer(history),
devTools: false,
middleware: (getDefaultMiddleware)=>getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
}).concat(sagaMiddleware)
})

sagaMiddleware.run(RootSaga);

export type RootState = ReturnType <typeof store.getState>;



