import { FC, useEffect, useMemo, useState } from "react";
import "./profile.scss";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../../../components/UI/TextInput";
import SelectDropdown from "../../../../components/UI/DropDown/SelectDropDown";
import { dateArray, yearArray } from "../../../../utils/helper";
import { COUNTRY, GENDER_LIST, GENDER_LIST_STA, GENRE_LIST, MONTH_LIST } from "../../../../constants/app";
import { getMakeDefaultCardApi, getMemberCardlessApi, memberUpdateApi, removedCard, updateMemberAttributeApi } from "../../../../services/auth";
import { toast } from "react-toastify";
import { getSavedCardWatcher, memberValidationSuccess, setUserDetails } from "../../../../store/auth/action";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import moment from "moment";
import { IMAGE_URL_CONFIG } from "../../../../constants/url";
import { CardDetail, IDropDown } from "../../../../models/common";
import AddCard from "../../../../components/Menu/AuthMenu/ManageCards/Add";
import { sortBy } from "lodash";

interface IProfileProps { }

const Profile: FC<IProfileProps> = () => {

  const { countryId, userDetails, memberDetail, cinemas, savedCards, currentCinema } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      userDetails: state.authReducer.userDetails,
      memberDetail: state.authReducer.memberDetail,
      cinemas: state.applicationReducer.cinemas,
      savedCards: state.authReducer.savedCards,
      currentCinema: state.applicationReducer.currentCinema
    })
  );

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [genderList, setGenderList] = useState<{ label: string; value: any }[]>(
    []
  );
  const [cardDetails, setCardDetails] = useState<CardDetail[]>([]);
  const [showAddForm, setAddForm] = useState(false);
  const [tab, setTab] = useState({
    personal: false,
    account: false,
    card: false
  })
  const [cinemaList, setCinemaList] = useState<IDropDown[]>([]);

  useEffect(() => {
    if ([COUNTRY.AUS, COUNTRY.NZ].includes(countryId)) {
      setGenderList(GENDER_LIST);
    } else if ([COUNTRY.STA, COUNTRY.ANG].includes(countryId)) {
      setGenderList(GENDER_LIST_STA);
    }
  }, [countryId]);

  useEffect(() => {
    if (cinemas) {
      let tempCinemas: any = [];
      Object.keys(cinemas).forEach(function (key) {
        const arrList = cinemas[key];
        arrList.forEach((item: any) => {
          tempCinemas.push({
            label: `${item.name}, ${item.stateCode}`,
            value: `${item.slug}`,
          });
        });
      });
      setCinemaList(sortBy(tempCinemas, (row) => row.label));
    }
  }, [cinemas]);

  const onChangTab = (field: keyof typeof tab, state: boolean) => {
    const tabData = {
      personal: false,
      account: false,
      card: false
    };
    tabData[field] = state;
    setTab(tabData);
    setFieldValue('currentPassword', '');
    setFieldValue('newPassword', '');
    setFieldValue('confirmPassword', '');
  }

  const updateProfile = async (payload: any) => {
    const {
      response: {
        data: {
          data: { status, memberId, errorMessage },
          statusCode,
        },
      },
    } = (await updateMemberAttributeApi(payload)) as any;
    if (status === "true" && statusCode === 200) {
      let memberID = memberId;

      const memberFormValues = {
        MemberRefId: userDetails?.vista_memberid,
        countryId: countryId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: userDetails?.email,
        updatedEmail: values.email,
        gender: values.gender,
        phoneNumber: values.phoneNumber,
        zipCode: values.postCode,
        dateOfBirth: `${values.year}-${values.month}-${values.day}`,
        suburb: values.suburb,
        city: values.city,
        cardNumber: userDetails?.cardNumber,
        memberId: memberId,
        preferredGenres: values.genres,
        weeklyNews: values.weeklyNews,
      };

      const {
        response: {
          data: {
            data: { ErrorDescription },
          },
        },
      } = (await memberUpdateApi(memberFormValues)) as any;

      let {
        response: {
          data: {
            data,
            statusCode
          },
        },

      } = (await getMemberCardlessApi({
        memberRefId: memberID,
        emailId: values.email,
      })) as any;
      if (statusCode === 200) {
        const {
          Email,
          FirstName,
          LastName,
          CardNumber,
          Country_id,
          Theatre_id,
          Weekly_news,
          Online,
          vista_memberid,
          ZipCode,
          Gender,
          MobilePhone,
          DateOfBirth,
          Suburb,
        } = data;

        dispatch(
          setUserDetails({
            ...userDetails,
            countryId: countryId,
            email: Email,
            firstName: FirstName,
            id: memberID,
            lastName: LastName,
            phoneNumber: MobilePhone,
            cardNumber: CardNumber,
            weekly_news: Weekly_news,
            theatre_id: Theatre_id,
            online: Online,
            vista_memberid: vista_memberid,
            zipCode: ZipCode,
            dateOfBirth: DateOfBirth
              ? moment(DateOfBirth, 'YYYY-MM-DD').format(
                "YYYY-MM-DD"
              )
              : "",
            gender: Gender,
            preferredGenres: values.genres.join(","),
            Suburb: Suburb,
            city: Suburb,
          })
        );

      }

      if (values.newPassword) {
        toast.success(
          "Your account has been updated successfully.", {
          delay: 3000
        });
        onChangTab('account', false)
      } else {
        toast.success("Your profile has been updated successfuly!!!", { delay: 3000 });
        onChangTab('personal', false)
      }

    } else {
      setErrorMessage(errorMessage);
    }
  };

  const { handleChange, handleSubmit, values, errors, touched, setFieldValue } =
    useFormik({
      validationSchema: tab.personal ? createValidationSchema("updateProfileMembership") : createValidationSchema("accountPasswordUpdate"),
      enableReinitialize: true,
      initialValues: {
        countryId: countryId,
        firstName: userDetails?.firstName || "",
        lastName: userDetails?.lastName || "",
        email: userDetails?.email || "",
        updatedEmail: userDetails?.email || "",
        phoneNumber: userDetails?.phoneNumber
          ? userDetails?.phoneNumber?.replace(/[^+\d]+/g, "")?.replace("+", "")
          : "",
        suburb: memberDetail?.LoyaltyMember?.Suburb || "",
        city: userDetails?.Suburb || "",
        gender: userDetails?.gender || "",
        genres:
          typeof memberDetail?.LoyaltyMember?.PreferredGenres === "string" ||
            memberDetail?.LoyaltyMember?.PreferredGenres === null ||
            memberDetail?.LoyaltyMember?.PreferredGenres === undefined
            ? []
            : memberDetail?.LoyaltyMember?.PreferredGenres,
        cinemaId: userDetails?.theatre_id || "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        postCode: userDetails?.zipCode || "",
        day: userDetails?.dateOfBirth
          ? moment(userDetails.dateOfBirth).format("DD")
          : "",
        month: userDetails?.dateOfBirth
          ? moment(userDetails.dateOfBirth).format("MM")
          : "",
        year: userDetails?.dateOfBirth
          ? moment(userDetails.dateOfBirth).format("YYYY")
          : "",
        weeklyNews: memberDetail?.LoyaltyMember?.SendNewsletter ? 1 : 0,
        preferredLocation: userDetails?.theatreId || userDetails?.theatre_id || "",
      },
      onSubmit: async (values) => {

        const updateProfilePayload = {
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender,
          dateOfBirth:`${values.year}-${values.month}-${values.day}`,
          email: values.email,
          updatedEmail: values.updatedEmail,
          phoneNumber: values.phoneNumber,
          preferredGenres: values.genres.join(","),
          resetPassword: values.currentPassword && values.newPassword?true:false,
          oldPassword: values.currentPassword,
          newPassword: values.newPassword,
          countryId: countryId,
          id: userDetails?.id,
          weeklyNews: values.weeklyNews,
          theatreId: values.preferredLocation,
          memberRefId: userDetails?.vista_memberid,
          cardNumber: userDetails?.cardNumber,
          request: 'updateProfileNew',
        };

        const {
          response: {
            data: {
              memberDetails: memberDetailResult,
              userDetails: userResult,
              memberId
            },
          },
        } = (await updateMemberAttributeApi(updateProfilePayload)) as any;
        if (memberDetailResult.statusCode && memberDetailResult.statusCode === 200 && memberDetailResult.data) {
          dispatch(memberValidationSuccess(memberDetailResult.data));
      }
      if (userResult.statusCode && userResult.statusCode === 200 && userResult.data && userResult.data.result && userResult.data.result.Member) {
          const { data: { result: { Member } } } = userResult
          dispatch(setUserDetails({
              ...userDetails,
              countryId: countryId,
              email: Member.Email,
              firstName: Member.FirstName,
              id: memberId,
              lastName: Member.LastName,
              phoneNumber: values.phoneNumber,
              cardNumber: Member.CardNumber,
              weekly_news: values.weeklyNews,
              dateOfBirth:`${values.year}-${values.month}-${values.day}`,
              gender: Member.Gender,
              preferredGenres: Member.PreferredGenres,
          }))
      }
      if (values.newPassword) {
        toast.success(
          "Your account has been updated successfully.", {
          delay: 3000
        });
        onChangTab('account', false)
      } else {
        toast.success("Your profile has been updated successfuly!!!", { delay: 3000 });
        onChangTab('personal', false)
      }
      },
    });


  const onChangeWeeklyLetter = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFieldValue("weeklyNews", checked ? 1 : 0);
  };


  const preferredGenres = useMemo(() => {
    return GENRE_LIST.filter(genre => userDetails?.preferredGenres?.includes(`${genre.value}`)).map(v => v.label).join(', ');
  }, [userDetails])

  const getCards = () => {
    dispatch(
      getSavedCardWatcher({
        memberRefId: userDetails?.result?.memberRefId!,
        countryId: countryId,
        emailId: userDetails?.email!,
      })
    );
  }
  useEffect(() => {
    if (userDetails) {
      getCards();
    }
  }, [userDetails]);

  useEffect(() => {
    if (savedCards) {
      setCardDetails(
        savedCards.map((card: CardDetail) => {
          return {
            ...card,
            cardNumber: card.cardNumber ? card.cardNumber.slice(-4) : "",
          };
        })
      );
    }
  }, [savedCards]);

  const addCreditCard = () => {
    setAddForm(true);
  }

  const onReset = () => {
    getCards();
    setAddForm(false);
  }

  const deleteCard = async (deleteCards: CardDetail) => {
    await removedCard({
      memberRefId: userDetails?.result?.memberRefId!,
      token: deleteCards?.token!,
      countryId: `${countryId}`,
      emailId: userDetails?.email!,
    }).then((res: any) => {
      const status = res?.data?.status;
      if (status === 200) {
        toast.success("Card deleted successfully");
        getCards();
      }
    });
  };

  const setDefaultCardBtn = async (card: CardDetail) => {
    if (userDetails) {
      const {
        data: { statusCode, data },
      } = (await getMakeDefaultCardApi({
        memberRefId: userDetails?.result?.memberRefId!,
        token: card.token,
        countryId: countryId,
        emailId: userDetails?.email,
      })) as any;

      if (statusCode === 200) {
        toast.success("Default Payment Option successfully updated");
        dispatch(
          getSavedCardWatcher({
            memberRefId: userDetails?.result?.memberRefId!,
            countryId: countryId,
            emailId: userDetails?.email!,
          })
        );
      }
    }
  };

  return (
    //  Profile
    <div className="profile">
      {tab.personal ? <div className="profile_update">
        <div className="profile_update_details">
          <div className="container">
            <div>
              <h3>Personal details</h3>
            </div>
            <div className="profile_update_details_body">
              <div className="row">
                <div className="col-12 col-md-4">
                  <label className="form-label">First Name*</label>
                  <div className="form-group">
                    <TextInput
                      value={values.firstName}
                      field={"firstName"}
                      handleChange={handleChange}
                      touched={touched.firstName!}
                      error={errors.firstName}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Last Name*</label>
                  <div className="form-group">
                    <TextInput
                      value={values.lastName}
                      field={"lastName"}
                      handleChange={handleChange}
                      touched={touched.lastName!}
                      error={errors.lastName}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Gender</label>
                  <div className="form-group">
                    <SelectDropdown
                      field={"gender"}
                      options={genderList}
                      setFieldValue={setFieldValue}
                      touched={touched.gender!}
                      error={errors.gender}
                      value={values.gender}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <label className="form-label">Contact Number*</label>
                  <div className="form-group position-relative">
                    <TextInput
                      value={values.phoneNumber}
                      field={"phoneNumber"}
                      handleChange={handleChange}
                      touched={touched.phoneNumber!}
                      error={errors.phoneNumber}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <label className="form-label">Date of Birth*</label>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-4 col-md-4">
                        <SelectDropdown
                          field={"day"}
                          options={dateArray().map((v) => {
                            v.label = Math.round(+v.label).toString();
                            return v;
                          })}
                          setFieldValue={setFieldValue}
                          touched={touched.day!}
                          error={errors.day}
                          value={values.day}
                          isDisabled={true}
                        />
                      </div>
                      <div className="col-4 col-md-4">
                        <SelectDropdown
                          field={"month"}
                          options={MONTH_LIST}
                          setFieldValue={setFieldValue}
                          touched={touched.month!}
                          error={errors.month}
                          value={values.month}
                          isDisabled={true}
                        />
                      </div>
                      <div className="col-4 col-md-4">
                        <SelectDropdown
                          field={"year"}
                          options={yearArray()}
                          setFieldValue={setFieldValue}
                          touched={touched.year!}
                          error={errors.year}
                          value={values.year}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <label className="form-label">Preferred Genres</label>
                  <div className="form-group">
                    <SelectDropdown
                      field={"genres"}
                      options={GENRE_LIST}
                      setFieldValue={setFieldValue}
                      touched={touched.genres!}
                      error={errors.genres}
                      value={values.genres}
                      isMulti={true}
                    />
                  </div>
                </div>
                {countryId === COUNTRY.AUS || countryId === COUNTRY.NZ ? <div className="col-12 col-md-4">
                  <label className="form-label">Preferred Cinema Location*</label>
                  <div className="form-group">
                    <SelectDropdown
                      field={"preferredLocation"}
                      options={cinemaList}
                      setFieldValue={setFieldValue}
                      touched={touched.preferredLocation!}
                      error={errors.preferredLocation}
                      value={values.preferredLocation}
                    />
                  </div>
                </div> : null}
                {/* <div className="col-12 col-md-4">
                  <label className="form-label">Suburb</label>
                  <div className="form-group">
                    <TextInput
                      value={values.suburb}
                      field={"suburb"}
                      handleChange={handleChange}
                      touched={touched.suburb!}
                      error={errors.suburb}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="err-message">{errorMessage}</div>
            <div className="submit_section">
              <button className="btn btn_grey" onClick={() => onChangTab('personal', false)}>CANCEL</button>
              <button className="btn btn_green" type="button" onClick={() => handleSubmit()}>SAVE CHANGES</button>
            </div>
          </div>
        </div>
      </div>
        :

        <div className="profile_details">
          <div className="container">
            <p>Profile details</p>
            <div className="profile_data">
              <div className="wrapper">
                <div>
                  <label>First Name</label>
                  <span>{userDetails?.firstName}</span>
                </div>
                <div>
                  <label>Last Name</label>
                  <span>{userDetails?.lastName}</span>
                </div>
              </div>
              <div className="wrapper">
                <div>
                  <label>Contact Number</label>
                  <span>{userDetails?.phoneNumber?.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')}</span>
                </div>
                <div>
                  <label>Date Of Birth</label>
                  <span>{moment(userDetails?.dateOfBirth).format('D MMMM YYYY')}</span>
                </div>
              </div>
              <div className="wrapper">
                <div>
                  <label>Preferred Location</label>
                  <span className="titlecase">{countryId === COUNTRY.STA ? 'State Cinema' : countryId === COUNTRY.ANG ? 'Angelika South City Square' : userDetails?.theatreId}</span>
                </div>
                {preferredGenres ? <div>
                  <label>Preferred Genres</label>
                  <span>{preferredGenres}</span>
                </div> : null}
              </div>
            </div>
            <div><button className="btn btn_black" onClick={() => onChangTab('personal', true)}>EDIT PERSONAL DETAILS</button></div>
          </div>
        </div>
      }
      {/* Accounts */}
      {tab.account ? <div className="account_update">
        <div className="account_update_details">
          <div className="container">
            <div>
              <h3>Account Details</h3>
            </div>
            <div className="account_update_details_body">
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label">Email Address*</label>
                  <div className="form-group">
                    <TextInput
                      value={values.updatedEmail}
                      field={"updatedEmail"}
                      handleChange={handleChange}
                      touched={touched.updatedEmail!}
                      error={errors.updatedEmail}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Current Password</label>
                  <div className="form-group">
                    <TextInput
                      type="password"
                      value={values.currentPassword}
                      field={"currentPassword"}
                      handleChange={handleChange}
                      touched={touched.currentPassword!}
                      error={errors.currentPassword}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label">New Password</label>
                  <div className="form-group">
                    <TextInput
                      type="password"
                      value={values.newPassword}
                      field={"newPassword"}
                      handleChange={handleChange}
                      touched={touched.newPassword!}
                      error={errors.newPassword}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="form-label">Confirm Password</label>
                  <div className="form-group">
                    <TextInput
                      type="password"
                      value={values.confirmPassword}
                      field={"confirmPassword"}
                      handleChange={handleChange}
                      touched={touched.confirmPassword!}
                      error={errors.confirmPassword}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 input-tag">
                <div className="custom _checkbox p-default form-group d-flex align-items-center">
                  <input
                    type="checkbox"
                    value="1"
                    onClick={onChangeWeeklyLetter}
                    checked={values.weeklyNews === 1 ? true : false}
                    className="checkbox"
                  />
                  <div className="state p-primary">
                    <label className="checkbox-label">
                      {countryId === COUNTRY.ANG
                        ? "Keep me updated with Angelika Cinemas offers and news"
                        : countryId === COUNTRY.STA
                          ? `Keep me updated with Loyalty weekly news`
                          : `Keep me updated with Reading Cinemas weekly news.`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="err-message">{errorMessage}</div>
            <div className="submit_section">
              <button className="btn btn_grey" onClick={() => onChangTab('account', false)}>CANCEL</button>
              <button className="btn btn_green" type="button" onClick={() => handleSubmit()}>SAVE CHANGES</button>
            </div>
          </div>
        </div>
      </div>
        :

        <div className="account_details">
          <div className="container">
            <p>Account details</p>
            <div className="email_detail">
              <label>Email</label>
              <div className="field">{userDetails?.email}</div>
            </div>
            <div className="weekly-info">
              <img src={
                IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                "tick_circle.svg"
              } alt="" />
              <div>Keep me updated with {countryId === COUNTRY.AUS || countryId === COUNTRY.NZ ? 'Reading' : 'Angelika'} Cinemas offers and news</div>
            </div>
            <div className="btn_group">
              <button className="btn btn_black" onClick={() => onChangTab('account', true)}>EDIT ACCOUNT DETAILS</button>
              <button className="btn btn_grey" onClick={() => onChangTab('account', true)}>CHANGE MY PASSWORD</button>
            </div>
          </div>
        </div>}

      {/* Save Cards */}
      {tab.card ?
        <div className="saved_cards_details">
          <div className="update_card_details">
            <div className="container">
              <div>
                <h3>Saved credit cards</h3>
              </div>
              {showAddForm ? <AddCard
                isEdit={false}
                onReset={onReset}
                fromPage={'profile'} /> : <>
                <div className="card_data">
                  {cardDetails && cardDetails.length > 0 ? cardDetails.map((card: CardDetail, i: number) => <div className="wrapper">
                    <div className="column">
                      <label>Card Number</label>
                      <div className="field">Ending in ...{card.cardNumber}</div>
                    </div>
                    <div className="column">
                      <label>Expires</label>
                      <div className="field">{card.expiryDate}</div>
                    </div>
                    <div className="default_card" onClick={() => setDefaultCardBtn(card)}>
                      <input className="cust" type="radio" name="default_checkbox" id={`default_chk_${i}`} defaultChecked={card.defaultCard ? true : false} />
                      <label className="field" htmlFor={`default_chk_${i}`}><div>Set as default card</div></label>
                    </div>
                    <div className="remove_card" onClick={() => deleteCard(card)}>
                      <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'delete-icon.svg'} />
                      <div>Remove Card</div>
                    </div>
                  </div>) : <div className="no-cards-wrapper">
                    <div className="field">No cards available</div>
                  </div>}
                </div>
                <div>
                  <button className="btn btn_black btn_add" onClick={addCreditCard}>
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'plus_16x16_white.svg'} />
                    <span className="px-3" >Add Credit Card</span>
                  </button>
                  <button className="btn btn_grey" onClick={() => onChangTab('card', false)}>CANCEL</button></div></>}
            </div>
          </div>
        </div> : <div className="saved_cards_details">
          <div className="container">
            <p>Saved credit cards</p>
            <div className="card_data">
              {cardDetails && cardDetails.length > 0 ? cardDetails.map((card: CardDetail) => <div className="wrapper">
                <div className="column">
                  <label>Card Number</label>
                  <div className="field">Ending in ...{card.cardNumber}</div>
                </div>
                <div className="column">
                  <label>Expires</label>
                  <div className="field">{card.expiryDate}</div>
                </div>
                <div className="default_card">
                  {card.defaultCard == 1 ? <><img src={
                    IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                    "tick_circle.svg"
                  } alt="" />
                    <div>Default card</div></> : null}
                </div>
              </div>) : <div className="no-cards-wrapper">
                <div className="field">No cards available</div>
              </div>}
            </div>
            <div><button className="btn btn_black" onClick={() => onChangTab('card', true)}>{'Edit Saved Credit Cards'}</button></div>
          </div>
        </div>}
    </div>
  );
};

export default Profile;

